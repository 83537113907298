<template>
    <div class="interfaceConfig-configPop">
        <!-- <div class="handle-btn">
            <el-button type="primary" size="small" @click="handleSave">
                保存
            </el-button>
        </div> -->
        <el-form
            ref="form"
            :model="form"
            label-width="150px"
            class="form-con"
        >
            <el-form-item label="接口名称">
                <el-input v-model="form.name" disabled></el-input>
            </el-form-item>
            <el-form-item label="接口说明">
                <el-input v-model="form.remarks" disabled></el-input>
            </el-form-item>
            <el-form-item label="服务地址">
                <el-input v-model="serviceUrl" disabled></el-input>
            </el-form-item>
            <el-form-item label="接口模式">
                <el-select v-model="form.interface_mode" placeholder="请选择接口模式" disabled>
                    <el-option label="覆盖" :value="0"></el-option>
                    <el-option label="追加" :value="1"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="container">
            <div class="container-slider">
                <div class="btn-con">
                    <el-button type="primary" size="small" @click="addDataConfig">
                        添加
                    </el-button>
                </div>
                <el-table
                    :data="tableData"
                    stripe
                    style="width: 100%"
                    height="calc(100% - 36px)"
                >
                    >
                    <el-table-column
                        label="选择"
                        width="55"
                    >
                        <template slot-scope="scope">
                            <el-radio
                                v-model="radio"
                                :label="scope.row"
                                @change.native="getTemplateRow(scope.$index,scope.row)"
                            >
                                <i></i>
                            </el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="采集接口数据表"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.entity_name }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="right-table">
                <div class="btn-con">
                    <el-button type="primary" size="small" @click="saveData">
                        保存
                    </el-button>
                </div>
                <el-table
                    :data="tableDataConfig"
                    stripe
                    style="width: 100%"
                    height="calc(100% - 36px)"
                >
                    >
                    <el-table-column>
                        <!-- eslint-disable-next-line vue/no-unused-vars -->
                        <template slot="header" slot-scope="scope">
                            <el-checkbox
                                :indeterminate="isIndeterminateEffective"
                                v-model="checkAllIsEffective"
                                @change="handleCheckAllIsEffective"
                            >
                                可插入列
                            </el-checkbox>
                        </template>
                        <template slot-scope="scope">
                            <el-checkbox
                                @change="changeEnableClums($event,scope.row)"
                                name="enableClums"
                                :value="scope.row.data_id"
                                v-model="scope.row.is_effective"
                            ></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="中文名称"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.data_name_cn }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="英文名称"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.data_name }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="类型"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.data_type }}
                        </template>
                    </el-table-column>
                    <el-table-column>
                        <!-- eslint-disable-next-line vue/no-unused-vars -->
                        <template slot="header" slot-scope="scope">
                            <el-checkbox
                                v-model="checkAllIsRequired"
                                @change="handleCheckAllIsRequired"
                                :indeterminate="isIndeterminateRequired"
                            >
                                必填
                            </el-checkbox>
                        </template>
                        <template slot-scope="scope">
                            <el-checkbox
                                @change="changeDefaultOperator($event,scope.row)"
                                name="defaultOperators"
                                v-model="scope.row.is_required"
                            ></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="过滤条件"
                    >
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.default_operator"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            serviceUrl: 'http://39.106.58.200:31000/swagger/index.html', // 接口服务地址
            validDate: '', // （合并的）有效期
            is_enabled: true, // 是否启用
            form: {},
            tableData: [

            ], // 数据库表
            pagetion: {
                pageindex: 1,
                pagesize: 999,
            },
            radio: '',
            tableDataConfig: [], // 数据表对应字段
            checkIsEffective: [],
            checkIsRequired: [],
            checkAllIsEffective: false,
            checkAllIsRequired: false,
            isIndeterminateEffective: true,
            isIndeterminateRequired: true,
            entity_id: '',
        };
    },
    watch: {},
    computed: {},
    methods: {
        getTemplateRow() {
            this.selectData = this.radio;
            this.getDataConfig(this.selectData);
        },
        // 获取接口数据表
        getEntityRelation() {
            this.$axios
                .get('/interfaceApi/datacenter/interface/entity/relation/getpage/' + this.extr.rowData.id + '?pageindex='
                + this.pagetion.pageindex + '&pagesize=' + this.pagetion.pagesize)
                .then(res => {
                    if (res) {
                        this.tableData = res.rows;
                        if (res.rows[0]) {
                            this.radio = res.rows[0];
                            this.selectData = this.radio;
                            this.getDataConfig(this.selectData);
                        }
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取数据表对应字段
        getDataConfig(data) {
            this.$axios
                .get('/interfaceApi/datacenter/interface/data/config/getpage/' + this.extr.rowData.id + '/' + data.entity_id
                + '?pageindex=1&pagesize=999')
                .then(res => {
                    if (res) {
                        res.rows.map(item => {
                            item.is_effective = item.is_effective === 1 ? true : false;
                            item.is_required = item.is_required === 1 ? true : false;
                        });
                        this.tableDataConfig = res.rows;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存数据表对应字段
        saveData() {
            const data = JSON.parse(JSON.stringify(this.tableDataConfig));
            data.map(item => {
                item.is_effective = Boolean(item.is_effective) === true ? 1 : 0;
                item.is_required = Boolean(item.is_required) === true ? 1 : 0;
            });
            this.$axios
                .post('/interfaceApi/datacenter/interface/data/config/save/' + this.extr.rowData.id + '/' + this.selectData.entity_id,
                    data)
                .then(res => {
                    if (res) {
                        this.$message.success('保存成功');
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 是否可插入列（全选、取消全选）
        handleCheckAllIsEffective(value) {
            this.checkIsEffective = [];
            if (value) {
                this.tableDataConfig.map(item => {
                    item.is_effective = true;
                    this.checkIsEffective.push(item.data_id);
                });
            } else {
                this.tableDataConfig.map(item => {
                    item.is_effective = false;
                });
            }
            this.isIndeterminateEffective = false;
        },
        // 是否必填（全选、取消全选）
        handleCheckAllIsRequired(value) {
            this.checkIsRequired = [];
            if (value) {
                this.tableDataConfig.map(item => {
                    item.is_required = true;
                    this.checkIsEffective.push(item.data_id);
                });
            } else {
                this.tableDataConfig.map(item => {
                    item.is_required = false;
                });
            }
            this.isIndeterminateRequired = false;
        },
        // 是否可插入列单选事件
        changeEnableClums(event, data) {
            if (event) {
                const arr = this.checkIsEffective.filter(item => item === data.data_id);
                if (arr.length < 1) {
                    this.checkIsEffective.push(data.data_id);
                }

            } else {
                const index = this.checkIsEffective.indexOf(data.data_id);
                this.checkIsEffective.splice(index, 1);
            }
            this.checkAllIsEffective = this.checkIsEffective.length === this.tableDataConfig.length ? true : false;
            this.tableDataConfig.map(item => {
                if (item.data_id === data.data_id) {
                    item.is_effective = event;
                }
            });

        },
        // 是否必填项单选事件
        changeDefaultOperator(event, data) {
            if (event) {
                const arr = this.checkIsRequired.filter(item => item === data.data_id);
                if (arr.length < 1) {
                    this.checkIsRequired.push(data.data_id);
                }

            } else {
                const index = this.checkIsRequired.indexOf(data.data_id);
                this.checkIsRequired.splice(index, 1);
            }
            this.checkAllIsRequired = this.checkIsRequired.length === this.tableDataConfig.length ? true : false;
            this.tableDataConfig.map(item => {
                if (item.data_id === data.data_id) {
                    item.is_required = event;
                }
            });
        },
        // 添加数据库表
        addDataConfig() {
            this.$toast(
                {
                    title: true,
                    text: '添加接口数据表',
                    type: 'eject',
                    width: '9rem',
                    height: '70%',
                    t_url: 'dataBaseCenter/dataCollection/interfaceConfig/addSliderPop',
                    viewPosition: 'view',
                    extr: { closeBtn: {}, closeCall: this.addDataConfigFinshed },
                });
        },
        // 添加数据库表选择完成
        addDataConfigFinshed(data) {
            const parseData = [];
            data.map(item => {
                parseData.push({ entity_id: item.entity_id, entity_name: item.name });
            });
            this.saveDataConfig(parseData);
        },
        // 保存数据库表
        saveDataConfig(data) {
            this.$axios
                .post('/interfaceApi/datacenter/interface/entity/relation/save/' + this.extr.rowData.id, data)
                .then(res => {
                    if (res) {
                        this.getEntityRelation();
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },


    },
    created() {
        this.form = this.extr.rowData;
        this.getEntityRelation();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.interfaceConfig-configPop
    width 100%
    padding .1rem
    height 100%
    overflow hidden
    .handle-btn
        text-align right
        margin-bottom .1rem
    .form-con
        padding-top .1rem
        padding-right .2rem
        border 1px solid #ccc
        margin-bottom 10px
        .el-form-item
            margin-bottom 10px
    .container
        height calc(100% - 258px)
        overflow hidden
        border 1px solid #ccc
        padding .10rem
        .el-table
            border 1px solid #f0f0f0
            border-radius 2px
            thead
                color #022782
                background #EDF0F5
                .el-checkbox__label
                    color #022782!important
                    font-size .14rem!important
                    font-weight bold
                th
                    padding .06rem 0
                    background #EDF0F5
                    text-align center
            tr
                td
                    padding .06rem 0
                    border-bottom 0
                    text-align center
                &.el-table__row--striped
                        background #f8fafd
        .table-page
            text-align right
            margin-top .1rem
        .container-slider
            width 3rem
            float left
            height 100%
            .btn-con
                text-align right
                margin-bottom 5px
        .right-table
            margin-left 3.1rem
            height 100%
            .btn-con
                text-align right
                margin-bottom 5px
</style>